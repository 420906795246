// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Auth_auth__-scmW {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.Auth_auth_title__Tdne3 {
  font-family: "Poppins";
  font-weight: 400;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.Auth_auth__-scmW input {
  width: 300px;
  height: 45px;
  border: 1px solid var(--black);
  border-radius: 10px;
  padding: 0 10px;
  font-family: "Poppins";
  font-weight: 400;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.Auth_auth_button__XKH\\+t {
  font-family: "Poppins";
  font-weight: 400;
  font-weight: 500;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  width: 150px;
  height: 45px;
  background-color: var(--black);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  font-size: 20px;
  transition-duration: 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/Auth.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,sBAAA;EACA,SAAA;AACF;AAAE;EACE,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,YAAA;EACA,YAAA;EACA,8BAAA;EACA,mBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAEJ;AAAE;EACE,sBAAA;EACA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,YAAA;EACA,YAAA;EACA,8BAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,yBAAA;AAEJ","sourcesContent":[".auth {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  gap: 20px;\n  &_title {\n    font-family: \"Poppins\";\n    font-weight: 400;\n    font-weight: 500;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 120%;\n  }\n  input {\n    width: 300px;\n    height: 45px;\n    border: 1px solid var(--black);\n    border-radius: 10px;\n    padding: 0 10px;\n    font-family: \"Poppins\";\n    font-weight: 400;\n    font-weight: 500;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 120%;\n  }\n  &_button {\n    font-family: \"Poppins\";\n    font-weight: 400;\n    font-weight: 500;\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 120%;\n    width: 150px;\n    height: 45px;\n    background-color: var(--black);\n    color: var(--white);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    border-radius: 10px;\n    cursor: pointer;\n    font-size: 20px;\n    transition-duration: 0.3s;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"auth": `Auth_auth__-scmW`,
	"auth_title": `Auth_auth_title__Tdne3`,
	"auth_button": `Auth_auth_button__XKH+t`
};
export default ___CSS_LOADER_EXPORT___;
